import { MainLayout } from '../src/MainLayout';
import { ErrorPageTemplate } from '../src/components/templates/ErrorPageTemplate';
import React from 'react';

export default function NotFound() {
  return (
    <MainLayout>
      <ErrorPageTemplate message="Not found" />
    </MainLayout>
  );
}
