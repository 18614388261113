import React from 'react';
import Link from 'next/link';

export interface ErrorPageTemplateProps {
  /**
   * Message displayed on the page
   */
  message: string;
  /**
   * Address of the image that will be displayed below the text
   */
  imageUrl?: string;
  /**
   * link to home
   */
  home?: string;
}

export const ErrorPageTemplate: React.FC<React.PropsWithChildren<ErrorPageTemplateProps>> = ({
  message,
  imageUrl,
  home = '/',
}) => (
  <section className="error-message text-center">
    <h1 data-testid="error-title">{message}</h1>
    {imageUrl && <img src={imageUrl} alt="error" />}
    <div className="text-center">
      <Link href={home}>Go back home</Link>
    </div>
  </section>
);
